@use "sass:math";
@use "variable" as *;
@use "function" as *;

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-bt {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

// https://ics.media/entry/200317/ に游ゴシックを追加
// https://blog.yoshinonaco.com/font-family-2020/#toc22
@mixin default-font {
  font-family: "Noto Sans JP", "游ゴシック体", YuGothic, "游ゴシック Medium",
    "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN",
    "Hiragino Sans", "Helvetica Neue", Arial, Meiryo, sans-serif;
}

// 游ゴシック体でboldを使いたいときはこちらを使用してください
@mixin default-font-bold {
  font: {
    family: "Noto Sans JP", "游ゴシック体", YuGothic, "游ゴシック Medium",
      "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN",
      "Hiragino Sans", "Helvetica Neue", Arial, Meiryo, sans-serif;
    weight: bold;
  }
}

@mixin font-poppins-medium {
  font: {
    family: "Poppins", sans-serif;
    weight: 500;
  }
}
@mixin font-poppins-semibold {
  font: {
    family: "Poppins", sans-serif;
    weight: 600;
  }
}

// 基本的にはmixinを使うが。font-size以外でfont-size関数を使いたいことがあるのでfunctionも残してある
@mixin font-size($length, $width: $mobile) {
  font-size: font-length($length, $width);
}
@mixin font-size-md($length, $scale: 0.25 * 0.5) {
  font-size: font-length-md($length);
}

// https://yuyakinoshita.com/blog/2020/01/20/line-height-crop/
@mixin line-height-crop($line-height, $once: true) {
  &::before {
    margin-top: calc((1 - #{$line-height}) * 0.5em);
    @if ($once == true) {
      content: "";
      display: block;
      width: 0;
      height: 0;
    }
  }
  &::after {
    margin-bottom: calc((1 - #{$line-height}) * 0.5em);
    @if ($once == true) {
      content: "";
      display: block;
      width: 0;
      height: 0;
    }
  }
}

@mixin mq($breakpoint: $medium) {
  @media screen and (min-width: $breakpoint) {
    @content;
  }
}

@mixin dp($ratio) {
  @media (-webkit-min-device-pixel-ratio: $ratio), (min-resolution: #{$ratio}dppx) {
    @content;
  }
}

// https: //ics.media/entry/221208/
@mixin hover {
  @media (any-hover: hover) {
    &:hover {
      @content;
    }
  }
}

@mixin aspectRatio($width, $height) {
  aspect-ratio: $width / $height;

  @supports not (aspect-ratio: auto) {
    position: relative;

    &::before {
      content: "";
      float: left;
      padding-top: calc(($height / $width) * 100%);
    }
    &::after {
      content: "";
      clear: both;
      display: block;
    }
    > img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
@mixin triangle($direction) {
  @if $direction == up {
    clip-path: polygon(0 100%, 100% 100%, 50% 0);
  } @else if $direction == down {
    clip-path: polygon(0 0, 100% 0, 50% 100%);
  } @else if $direction == left {
    clip-path: polygon(100% 0, 100% 100%, 0 50%);
  } @else if $direction == right {
    clip-path: polygon(0 0, 0 100%, 100% 50%);
  }
}
@mixin defaultEase {
  transition: ease-in-out 0.36s;
}
@mixin fullImage {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@mixin overInnerWide {
  margin-left: calc(50% - var(--vw, 1vw) * 50);
  margin-right: calc(50% - var(--vw, 1vw) * 50);
}
