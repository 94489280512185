@use "sass:math";
@use "global" as *;

.index-main__kv {
  // @include overInnerWide;
  position: fixed;
  top: 0;
  width: 100%;
  left: 0;
}
.index-kv {
  display: grid;
  height: 100vh;
  overflow: hidden;
}
.index-kv__item {
  grid-column: 1/2;
  grid-row: 1/2;
  max-height: 100vh;
}

.index-kv-copy {
  position: relative;
  z-index: 3;
  align-items: center;
  display: flex;
  justify-content: center;
  filter: drop-shadow(
    0 length(3) length(24) rgba(map-get($map: $colors, $key: "black"), 0.8)
  );
  will-change: filter;
  @include mq {
    filter: drop-shadow(
      0 length-md(3) length-md(6)
        rgba(map-get($map: $colors, $key: "black"), 0.3)
    );
  }
}
.index-kv-copy-letter {
  // filter: drop-shadow(
  //   0 length(3) length(24) rgba(map-get($map: $colors, $key: "black"), 0.8)
  // );
  // will-change: filter;
  // @include mq {
  //   filter: drop-shadow(
  //     0 length-md(3) length-md(6)
  //       rgba(map-get($map: $colors, $key: "black"), 0.3)
  //   );
  // }
}
.step-letter {
  // transform: rotateX(10deg);
  // transform-origin: bottom;
}
.index-kv-inner__main {
  display: block;
  margin: auto;
  width: 84%;
  height: auto;
  @include mq {
    width: auto;
  }
}
.index-kv-inner__sub {
  text-align: center;
  color: map-get($map: $colors, $key: "white");
  @include font-size(32);
  font-weight: bold;
  letter-spacing: 0.2em;
  white-space: pre-line;
  // will-change: filter;
  // filter: drop-shadow(
  //   0 length(3) length(12) rgba(map-get($map: $colors, $key: "black"), 0.5)
  // );
  margin-top: length(84);
  @include mq {
    margin-top: length-md(32);
    // filter: drop-shadow(
    //   0 length-md(3) length-md(6)
    //     rgba(map-get($map: $colors, $key: "black"), 0.3)
    // );
    @include font-size-md(18);
  }
}
.index-kv-wave {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  mix-blend-mode: color-burn;
}
.index-kv-bg {
  display: grid;
}
// .index-kv-bg__primary,
// .index-kv-bg__secondary {
//     &:before {
//     // transition: ease-in-out 2s;
//     content: "";
//     position: absolute;
//     left: 0;
//     top: 0;
//     width: 200%;
//     height: 100%;
//     z-index: 1;
//     background: rgb(255, 255, 255);
//     background: linear-gradient(
//       270deg,
//       rgba(255, 255, 255, 0) 0%,
//       rgba(255, 255, 255, 1) 35%,
//       rgba(255, 255, 255, 1) 100%
//     );
//   }
// }
.index-kv-bg__primary {
  position: relative;
  z-index: 1;
  grid-column: 1/2;
  grid-row: 1/2;
  position: relative;
  mask-image: url(../../../../img/index/bg_index_kv_mask_sp.png);
  mask-repeat: no-repeat;
  mask-size: cover;
  overflow: hidden;
  mask-position: bottom;
  height: 70%;
  @include mq {
    height: auto;
    mask-image: url(../../../../img/index/bg_index_kv_mask.png);
    // mask-image: url(../../../../../img/common/img_layer_mask.png);
  }
  &:before {
    // transition: ease-in-out 2s;
    // content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 200%;
    height: 100%;
    z-index: 1;
    background: rgb(255, 255, 255);
    background: linear-gradient(
      270deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 35%,
      rgba(255, 255, 255, 1) 100%
    );
  }
  &.is-active {
    &:before {
      // animation: gradAnimPrimary 3s ease-in-out infinite;
    }
  }
}
.kv-cover {
  position: absolute;
  background: rgb(255, 255, 255);
  width: 200%;
  height: 100%;
  top: 0;
  z-index: 1;
}
.kv-cover--primary {
  left: 0;
  background: linear-gradient(
    270deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 35%,
    rgba(255, 255, 255, 1) 100%
  );
}
.kv-cover--secondary {
  right: 0;

  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 35%,
    rgba(255, 255, 255, 1) 100%
  );
}
.index-kv-bg__secondary {
  position: relative;
  z-index: 0;
  grid-column: 1/2;
  grid-row: 1/2;
  height: 70%;
  margin-top: auto;
  @include mq {
    height: auto;

  }
  &:before {
    // transition: ease-in-out 2s;
    // content: "";
    position: absolute;
    right: 0;
    top: 0;
    width: 200%;
    height: 100%;
    z-index: 1;
    background: rgb(255, 255, 255);
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 35%,
      rgba(255, 255, 255, 1) 100%
    );
  }
  &.is-active {
    &:before {
      // animation: gradAnimSecondary 3s ease-in-out infinite;
    }
  }
}
@keyframes gradAnimPrimary {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-200%);
  }
}
@keyframes gradAnimSecondary {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(200%);
  }
}

.kv-slide--secondary,
.kv-secondary-wrap,
.kv-secondary-wrap__track,
.kv-secondary-slide {
  height: 100%;
}
