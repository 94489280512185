@use "sass:math";
@use "global" as *;

// news
.index-news__title {
  @include font-size(40);
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  @include mq {
    @include font-size-md(40);
  }
}
.index-news__wrap {
  background: rgba(map-get($map: $colors, $key: "lightGreen"), 0.2);
  padding: length(40);
  border-radius: length(16);
  margin-top: length(40);
  @include mq {
    border-radius: length-md(16);
    margin-top: length-md(24);
    padding: length-md(32);
  }
}
.news-inner {
  max-height: length(620);
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: length-md(8);
  }
  &::-webkit-scrollbar-track {
    background-color: rgba(
      $color: map-get($map: $colors, $key: "black"),
      $alpha: 0.15
    );
    padding: 2%;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 40px;
    background-color: #555;
  }
  @include mq {
    max-height: length-md(312);
  }
}
.news-wrap {
  background: map-get($map: $colors, $key: "white");
  padding: 0 length(40);
  margin-right: length(20);
  @include mq {
    padding: 0 length-md(32);
    margin-right: length-md(30);
  }
}
.news-list {
}
.news-list__item {
  padding: length(40) 0;
  display: grid;
  gap: length(24);
  @include mq {
    gap: length-md(26);
    display: flex;
    align-items: center;
    padding: length-md(32) 0;
  }
  &:not(:last-child) {
    border-bottom: 1px solid rgba(map-get($map: $colors, $key: "black"), 0.1);
  }
}
.news-head {
  @include font-size(22);
  display: flex;
  align-items: center;
  gap: length(24);
  @include mq {
    @include font-size-md(14);
    gap: length-md(24);
    min-width: length-md(210);
  }
}
.news-date {
}
.news-category {
  width: fit-content;
  border: 1px solid map-get($map: $colors, $key: "green");
  padding: length(4) length(32);
  border-radius: length(20);
  @include mq {
    padding: length-md(4) length-md(20);
    border-radius: length-md(15);
  }
}
.news-category__text {
  color: map-get($map: $colors, $key: "green");
}
.news-cont {
}
.news-cont__text {
}
.index-news__button {
  display: flex;
  justify-content: center;
  margin-top: length(72);
  @include mq {
    margin-top: length-md(40);
  }
}
