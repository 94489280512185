@use "sass:math";
@use "global" as *;

.index-main-section {
  position: relative;
  &:before {
    transition: opacity ease-in-out 0.6s;
    opacity: 0;
    visibility: hidden;
    @include overInnerWide;
    content: "";
    position: absolute;
    top: 65%;
    right: -60%;
    transform: translate(-50%, -50%);
    aspect-ratio: 768/300;
    width: calc(var(--vw, 1vw) * 100);
    background: url(../../../../img/index/bg_index_wave_blue.png) center center /
      contain no-repeat;
    @include mq {
      top: 40%;
      right: 0;
      transform: translate(0%, -50%);
      aspect-ratio: 1136/446;
      width: length-md(1136);
    }
  }
  &.is-visible {
    &:before {
      opacity: 1;
      visibility: visible;
    }
  }
}
.index-main-section--reverse {
  &:before {
    background: url(../../../../img/index/bg_index_wave_green.png) center center /
      contain no-repeat;
    @include mq {
      right: auto;
      left: 0;
    }
  }

  .index-main-section__title {
    @include mq {
      top: 0%;
      left: calc((length-md(160) + 0.5em) * -1);
      // transform: translate(100%, -50%);
      right: auto;
    }
  }
  .index-main-section__figure {
    margin-left: auto;
  }
  .index-main-section__label {
    text-align: right;
  }
}
.index-main-section__label {
  text-transform: uppercase;
  @include font-size(88);
  @include default-font-bold;
  opacity: 0.3;
  @include mq {
    @include font-size-md(88);
  }
}
.index-main-section__label--blue {
  color: map-get($map: $colors, $key: "pastelBlue");
}
.index-main-section__label--green {
  color: map-get($map: $colors, $key: "lightGreen");
}
.index-main-section__title {
  @include font-size(48);
  @include default-font-bold;
  letter-spacing: 0.08em;
  // top: calc((font-length(8) * 1.5) * -1);
  transform: translate(0%, 0%);
  white-space: pre-line;
  height: max-content;
  overflow: hidden;
  @include mq {
    // display: flex;
    @include font-size-md(40);
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
    position: absolute;
    top: 0%;
    right: calc((length-md(160) + 0.5em) * -1);
    line-height: 1;
  }
}
.index-main-section__figure {
  position: relative;
  @include mq {
    width: length-md(672);
  }
}
.index-section-figure {
  position: relative;
  width: 100%;
  aspect-ratio: 672/400;
  border-radius: length-md(16);
  &:before {
    content: "";
    aspect-ratio: 288/137;
    width: length(288);
    position: absolute;
    border-radius: length(6);
    opacity: 0;
    visibility: hidden;
    @include mq {
      border-radius: length-md(16);
      width: length-md(288);
      z-index: 1;
    }
  }
  &.is-visible {
    &:before {
      opacity: 0.3;
      visibility: visible;
    }
    .index-section-figure__image {
      clip-path: polygon(100% 0%, 100% 100%, 0% 100%, 0% 0%);
    }
  }
}
.index-section-figure--blue {
  &:before {
    transition: top ease-in-out 1s, right ease-in-out 1s, opacity ease-in-out 1s;
    background: map-get($map: $colors, $key: "pastelBlue");
    top: 0;
    right: 0;
  }
  &.is-visible {
    &:before {
      top: length(-30);
      right: length(-30);
      // transform: translate(length(-30)) length(-30);
      @include mq {
        top: length-md(-30);
        right: length-md(-30);
        // transform: translate(calc(length-md(60) * -1) length-md(-30));
      }
    }
  }
}
.index-section-figure--green {
  &:before {
    background: map-get($map: $colors, $key: "lightGreen");
    transition: bottom ease-in-out 1s, left ease-in-out 1s,
      opacity ease-in-out 1s;
    bottom: 0;
    left: 0;
    @include mq {
      transition: top ease-in-out 0.6s, right ease-in-out 0.6s,
        opacity ease-in-out 1s;
      top: 0;
      right: 0;
    }
  }
  &.is-visible {
    &:before {
      right: auto;
      bottom: auto;
      top: length(-30);
      left: length(-30);

      @include mq {
        top: auto;
        right: auto;
        bottom: length-md(-30);
        left: length-md(-30);
      }
    }
  }
}
.index-section-figure__image {
  transition: clip-path ease-in-out 0.6s;
  clip-path: polygon(100% 100%, 100% 100%, 0% 100%, 0% 100%);
  position: relative;
  z-index: 1;
}
.index-section-text {
  margin-top: length-md(40);
}
.index-section-button {
  margin-top: length(72);
  display: flex;
  justify-content: center;
  @include mq {
    justify-content: unset;
    margin-top: length-md(48);
  }
}
