@use "sass:math";
@use "global" as *;


// message

.index-main__message {
  margin-top: 100vh;
  width: calc(var(--vw, 1vw) * 100);
  @include overInnerWide;
}
// .index-message-mask {
//   position: absolute;
//   z-index: -999;
// }
.index-message {
  position: relative;
  bottom: -1px;
  mask-image: url(../../../../img/index/bg_index_blur_sp.png);
  mask-repeat: no-repeat;
  mask-size: cover;
  --webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  padding: length(300) length(96) length(400);
  @include mq {
    mask-image: url(../../../../img/index/bg_index_blur.svg);
    padding: length-md(278) length-md(228) length-md(368);
  }
  &:before,
  &:after {
    content: "";
    width: 100%;
    position: absolute;
  }
  &:before {
    background: rgb(255, 255, 255);
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 1) 40%,
      rgba(255, 255, 255, 0) 100%
    );
    height: 100%;
    left: 0;
    bottom: 0;
    z-index: -1;
  }
  &:after {
    width: 100%;
    position: absolute;
    bottom: 5%;
    left: 0;
    // opacity: 0.3;
    background: url(../../../../img/index/img_index_message_treat_sp.png) center /
      contain no-repeat;
    aspect-ratio: 873/172;
    @include mq {
      bottom: length-md(116);
      aspect-ratio: 1280/252;
      background: url(../../../../img/index/img_index_message_treat.png) center /
        contain no-repeat;
    }
  }
}

.index-message__text {
  @include font-size(32);
  @include default-font-bold;
  color: map-get($map: $colors, $key: "deepBlue");
  // white-space: pre-line;
  line-height: 2;
  // line-height: calc(56 / 24);
  @include mq {
    @include font-size-md(24);
  }
}
