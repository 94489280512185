@use "sass:math";
@use "global" as *;

@use "../../component/libs/splide-core.min.css";
@use "./index_kv";
@use "./index_news";
@use "./index_message";
@use "./index_sections";
.index-main {
  overflow: hidden;
  @include overInnerWide;
  width: calc(var(--vw, 1vw) * 100);
  padding: 0 vmin($side-width);

  @include mq {
    padding: 0 vw(144, $desktop);
  }
}
.index-main__cont {
  background: map-get($map: $colors, $key: "white");
  position: relative;
  z-index: 1;
  @include overInnerWide;
  width: calc(var(--vw, 1vw) * 100);
  padding: 0 vmin($side-width);

  @include mq {
    padding: 0 vw(144, $desktop);
  }
  &:not(:first-child) {
    padding-top: length-md(160);
  }
  &:after {
    @include overInnerWide;
    // content: "";
    width: 100%;
    width: calc(var(--vw, 1vw) * 100);
    height: 100%;
    position: absolute;
    top: 0%;
    left: 0%;
    background: map-get($map: $colors, $key: "white");
    z-index: -1;
  }
}
